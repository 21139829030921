import Model from './Model'

class FormField extends Model {
  get defaultValue () {
    return {
      value: null,
      description: null,
      info: null
    }
  }
}

export default FormField
