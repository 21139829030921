<template>
  <div v-show="!submitted" class="cc-form-wrapper">
    <!-- Do not delete vl-parent class. It's the vue-loading-overlay container class -->
    <form
      ref="formContainer"
      class="cc-form vl-parent"
      @submit.prevent>
      <div class="cc-form-inputList">
        <FormInput
          v-for="field in fields"
          :key="field.id"
          :field="field" />
      </div>
      <div class="cc-form-footer">
        <div class="cc-form-buttons">
          <button
            class="c2a c2a--main"
            type="submit"
            @click="submit">
            {{ $t('submit') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import FormInput from './FormInput.vue'
import Form from '@vanillaUtils/form/Form'
import FormField from '@vanilla/models/FormField'
import AnalyticsHub from '@vanillaUtils/AnalyticsHub'

export default {
  name: 'CCForm',

  components: {
    FormInput
  },

  mixins: [Form],

  data () {
    return {
      fields: [],
      submitted: false
    }
  },

  methods: {
    getValuesFromInput () {
      return this.fields.reduce((acc, field) => {
        acc[field.id] = field.value

        return acc
      }, {})
    },
    submit () {
      const loader = this.$loading.show({
        container: this.$refs.formContainer
      })
      this.saving = true

      this.$axios.post('/api/form-entries', {
        'values': this.getValuesFromInput(),
        'form_id': this.form.id,
        'page_id': window.document.getElementById('currentId').value,
        'page_complete_url': window.location.href,
        'language': this.$locale
      }).then(response => {
        this.$refs?.formContainer.closest('.vb-form')?.classList.add('cc-form--complete')
        this.submitted = true
        const element = window.document.getElementById('cc-' + this.form.id + '-confirmMessage')
        element.classList.add('cc-form-confirmMessage--active')
        this.$nextTick(_ => {
          window.scroll({
            top: element.offsetTop,
            behavior: 'smooth'
          })
        })

        AnalyticsHub.push({
          event: 'submit_form',
          form_type: this.form.name,
          form_success: true
        })
      }).catch(error => {
        this.handleError(error)

        AnalyticsHub.push({
          event: 'submit_form',
          form_type: this.form.name,
          form_success: false
        })
      }).finally(_ => {
        loader.hide()

        this.$nextTick(_ => {
          this.saving = false
        })
      })
    }
  },

  created () {
    this.fields = this.form.fields.map(field => new FormField(field))
    const element = window.document.getElementById('cc-' + this.form.id + '-confirmMessage')

    if (element) {
      element.classList.remove('cc-form-confirmMessage--active')
    }
  }
}
</script>
