/**
 * Initialize App
 */
import compiled from '@@compiled/js/'
import { createApp } from 'vue'
const init = []
const plugins = []
const mounted = []
const readyHolder = []

import.meta.glob([
  '/public/images/**',
])

Object.keys(compiled).forEach(compiledName => {
  if ('init' in compiled[compiledName]) {
    init.push(compiled[compiledName])
  }
  if ('install' in compiled[compiledName]) {
    plugins.push(compiled[compiledName])
  }
  if ('mounted' in compiled[compiledName]) {
    mounted.push(compiled[compiledName])
  }
  if ('ready' in compiled[compiledName]) {
    readyHolder.push(compiled[compiledName])
  }
})

window.app = createApp({})

/**
 * Init i18n
 */
import { createI18n } from 'vue-i18n'
import axios from 'axios'
import fr from '@@compiled/i18n/fr.json'
import en from '@@compiled/i18n/en.json'
const locale = window?.Cheetah?.locale || document?.documentElement?.lang || 'fr'
const i18n = createI18n({
  messages: { fr, en },
  fallbackLocale: 'en',
  locale,
  silentTranslationWarn: true, // to removes translation warning messages in console
  silentFallbackWarn: true
})

window.app.locale = locale
window.app.use(i18n)
window.app.$i18n = i18n.global
window.app.config.globalProperties.$locale = window.app.locale
window.app.config.globalProperties.$axios = axios

/**
 * Init
 */
init.forEach(exportObject => {
  exportObject.init()
})

/**
 * Global Loading
 */
plugins.forEach(plugin => {
  window.app.use(plugin)
})

/**
 * Mount application
 */
window.app.mount('#app')

/**
 * Mounted
 */
mounted.forEach(exportObject => {
  console.log('(main:) exportObject: ', exportObject.mounted, typeof exportObject.mounted)
  exportObject.mounted()
})

/**
 * Init Dom
 */
window.ready(() => {
  readyHolder.forEach(exportObject => {
    exportObject.ready()
  })
})
