<template>
  <div class="cc-selectInput">
    <VueSelect
      v-model="localValue"
      :options="options"
      :disabled="disabled || readonly"
      :clearable="clearable"
      :placeholder="calculatedPlaceholder"
      :multiple="multiple"
      :data-gtm-input="id"
      :data-gtm-input-name="slugLabel"
      @update:model-value="onChange"
      @search:focus="$emit('focus', $event)">
      <template #selected-option="{ label }">
        {{ getLabelOf(label) }}
      </template>
    </VueSelect>
  </div>
</template>

<script>
import UsesObjectValues from './mixins/UsesObjectValues'
import FormField from './mixins/FormField'

export default {
  name: 'CCSelectField',

  mixins: [UsesObjectValues, FormField],

  props: {
    modelValue: {
      type: [String, Array, null, Object],
      required: true,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    multiple: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    clearable: Boolean
  },

  data () {
    return {
      localValue: this.modelValue
    }
  },

  computed: {
    calculatedPlaceholder () {
      return this.placeholder ?? this.$t('pageList.inputPlaceholder')
    }
  },

  watch: {
    modelValue: {
      handler () {
        this.localValue = this.modelValue
      }
    }
  },

  methods: {
    getLabelOf (value) {
      const relatedOption = this.options.find(option => option.value === value)

      return relatedOption ? relatedOption.label : null
    },
    onChange (value) {
      if (value instanceof Array) {
        value = value.map(value => this.getValueOf(value))
      } else {
        value = this.getValueOf(value)
      }

      this.$emit('update:modelValue', value)
    }
  }
}
</script>
